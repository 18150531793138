// import logo from './logo.svg';
import './App.css';
import './firebaseConfig';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SingleProducts from './pages/SingleProducts';
import Home from './pages/Home';
import AllProducts from './pages/AllProducts';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Context from './context/Context';

function App() {
  return (
    <Context>
    <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home /> }/>
                <Route path="/Singleproducts/:id" element={ <SingleProducts /> }/>
                <Route path="/allproducts" element={ <AllProducts/> }/>
                <Route path="/signup" element={ <Register/> }/>
                <Route path="/profile" element={ <Profile/> }/>
            </Routes>
        </BrowserRouter>
    </Context>
  );
}

export default App;


import { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import { BsCurrencyRupee, BsHandbagFill, BsHouseDoorFill } from 'react-icons/bs'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {
  Breadcrumbs,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import p1 from '../img/ktytyj.webp';
import { collection, onSnapshot, or, orderBy, query, where, and } from 'firebase/firestore'
import { dataBase } from '../firebaseConfig'
import { useNavigate } from 'react-router-dom'
import { colors, connectivities, storages } from '../config/config'

const dummySortOptions = [
  { name: 'Newest', current: true, key: "createdAt", type: "DESC" },
  { name: 'Price: Low to High', current: false, key: "price", type: "ASC" },
  { name: 'Price: High to Low', current: false, key: "price", type: "DESC" },
]
const dummyFilters = [
  {
    id: 'colors',
    name: 'Color',
    options: colors.map(eachItem => {
      return {
        ...eachItem,
        checked: false
      }
    }),
  },
  {
    id: 'storages',
    name: 'Storage',
    options: storages.map(eachItem => {
      return {
        ...eachItem,
        checked: false
      }
    }),
  },
  {
    id: 'connectivities',
    name: 'Connectivity',
    options: connectivities.map(eachItem => {
      return {
        ...eachItem,
        checked: false
      }
    }),
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AllProducts = () => {
  const [profetch, setProFetch] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const [filters, updateFilters] = useState([...dummyFilters]);
  const [sortOptions, updateSortOptions] = useState([...dummySortOptions]);
  const [filterDataState, updateFilterData] = useState({});
  const [sortDataState, updateSortData] = useState({});

  const colRef = collection(dataBase, 'products');

  const dynamicSort = (property, type) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      var result = (a[property] < b[property]) ? type === "DESC" ? 1 : -1 : (a[property] > b[property]) ? type === "DESC" ? -1 : 1 : 0;
      return result * sortOrder;
    }
  };


  const fetchProducts = async (filterData, sortData) => {
    const localSortData = sortData ? {...sortData} : {...sortDataState};
    const localFilterData = filterData ? {...filterData} : {...filterDataState};
    let qry;
    if (!localFilterData || (localFilterData && !Object.keys(localFilterData))) {
      qry = query(colRef, orderBy("createdAt", "desc"));
    } else {
      const finalWhereClause = [];
      if (localFilterData.colors && localFilterData.colors.length) {
        const temp = [];
        localFilterData.colors.forEach(each => {
          temp.push(where(`colors.${each}`, "==", true))
        });
        finalWhereClause.push(or(...temp));
      }

      if (localFilterData.storages && localFilterData.storages.length) {
        const temp = [];
        localFilterData.storages.forEach(each => {
          temp.push(where(`storages.${each}`, "==", true))
        });
        finalWhereClause.push(or(...temp));
      }

      if (localFilterData.connectivities && localFilterData.connectivities.length) {
        const temp = [];
        localFilterData.connectivities.forEach(each => {
          temp.push(where(`connectivities.${each}`, "==", true))
        });
        finalWhereClause.push(or(...temp));
      }

      if (localFilterData.pSubCatagorys && localFilterData.pSubCatagorys.length) {
        const temp = [];
        localFilterData.pSubCatagorys.forEach(each => {
          temp.push(where(`pSubCatagorys.${each}`, "==", true))
        });
        finalWhereClause.push(or(...temp));
      }

      if (localFilterData.pCatagory && localFilterData.pCatagory.length) {
        const temp = [];
        localFilterData.pCatagory.forEach(each => {
          temp.push(where(`pCatagory.${each}`, "==", true))
        });
        finalWhereClause.push(or(...temp));
      }

      console.log("localFilterData--- ", localFilterData)

      qry = query(colRef, and(...finalWhereClause));
    }
    onSnapshot(qry, (QuerySnapshot) => {
      const test = QuerySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        pFlag: doc.data().pFlag,
        otherDetails: doc.data()
      })).filter(each => each.pFlag === 1);
      if (localSortData?.key) {
        test.sort(dynamicSort(localSortData.key, localSortData.type));
      }
      console.log("test ", test)
      setProFetch(test);
    });
  };
  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchSubCategories();
  }, []);


  const fetchCategories = async () => {
    if (filters.length < 5) {
      const colRef = collection(dataBase, 'catagory');
      const qry = query(colRef, orderBy("createdAt", "desc"));
      onSnapshot(qry, (QuerySnapshot) => {

        updateFilters(prevFilter => {
          return [
            ...prevFilter,
            {
              id: 'pCatagory',
              name: 'Category',
              options: QuerySnapshot.docs.map(doc => {
                return {
                  label: doc.data().catagory,
                  value: doc.id,
                  checked: false
                }
              }),
            },
          ]
        });

      });
    }

  };

  const fetchSubCategories = async () => {
    const colRef = collection(dataBase, 'subcatagory');
    const qry = query(colRef, orderBy("createdAt", "desc"));
    onSnapshot(qry, (QuerySnapshot) => {

      updateFilters(prevFilter => {
        return [
          ...prevFilter,
          {
            id: 'pSubCatagorys',
            name: 'Sub Categories',
            options: QuerySnapshot.docs.map(doc => {
              return {
                label: doc.data().SubCatagory,
                value: doc.id,
                checked: false
              }
            }),
          },
        ]
      });

    });
  };

  const updateSort = (index) => {
    const localSortOptions = [...sortOptions];
    localSortOptions.forEach((each, ind) => {
      each.current = false;
      if (ind === index) {
        each.current = true;
        fetchProducts(null, {key: each.key, type: each.type});
        updateSortData({key: each.key, type: each.type});
      }
    });
    updateSortOptions([...localSortOptions]);
  };


  const updateFilterSelection = (e, pIndex, childIndex) => {
    const backupFilter = [...filters];
    const localFilters = filters[pIndex].options;
    localFilters[childIndex].checked = !localFilters[childIndex].checked;

    backupFilter[pIndex].options = localFilters;
    const obj = {};
    backupFilter.forEach(each => {
      if (each.options.some(each1 => each1.checked)) {
        obj[each.id] = each.options.map(each1 => {
          if (each1.checked) {
            return each1.value;
          } else {
            return false;
          }
        }).filter(each1 => each1);
      }
    });
    updateFilters([...backupFilter]);
    fetchProducts(obj);
    updateFilterData({...obj});
  };

  return (
    <>
      <Header />
      <div>
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4 border-t border-gray-200">
                      {/* <h3 className="sr-only">Categories</h3> */}
                      {/* <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                        {subCategories.map((category) => (
                          <li key={category.name}>
                            <a href={category.href} className="block px-2 py-3">
                              {category.name}
                            </a>
                          </li>
                        ))}
                      </ul> */}

                      {filters.length >= 5 && [...filters.slice(0, 4), filters[4].id === "pCatagory" ? filters[5] : filters[4]].map((section) => (
                        <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                  <span className="font-medium text-gray-900">{section.name}</span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                    ) : (
                                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                      <input
                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        onChange={(e) => updateFilterSelection(e, filters.map(each => each.id).indexOf(section.id), optionIdx)}
                                        defaultChecked={option.checked}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <Breadcrumbs className='bg-inherit p-0 mt-4'>
              <a href="/" className="opacity-60">
                <BsHouseDoorFill />
              </a>
              <a href="#" className="opacity-60">
                <span>dynamic</span>
              </a>
              <a href="#">dynamic</a>
            </Breadcrumbs>
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 mt-2">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">New Arrivals(dynamic)</h1>
              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option, index) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <span
                                className={classNames(
                                  option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm cursor-pointer'
                                )}
                                onClick={() => updateSort(index)}
                              >
                                {option.name}
                              </span>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                  <span className="sr-only">View grid</span>
                  <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="sr-only">Filters</span>
                  <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            <section aria-labelledby="products-heading" className=" mt-6">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                <form className="hidden lg:block">
                  {/* <h3 className="sr-only">Categories</h3>
                  <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                    {subCategories.map((category) => (
                      <li key={category.name}>
                        <a href={category.href}>{category.name}</a>
                      </li>
                    ))}
                  </ul> */}

                  {filters.length >= 5 && [...filters.slice(0, 4), filters[4].id === "pCatagory" ? filters[5] : filters[4]].map((section, index) => (
                    <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">{section.name}</span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    onChange={(e) => updateFilterSelection(e, filters.map(each => each.id).indexOf(section.id), optionIdx)}
                                    defaultChecked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>

                {/* Product grid */}
                {/* <div className="grid grid-cols-3 gap-4"> */}
                {profetch.length ?
                  profetch.map(prod => {
                    return <div key={prod.id} className=" p-2.5 shadow-md rounded-xl " style={{
                      height: "fit-content"
                    }} onClick={() => navigate(`/Singleproducts/${prod.id}`)}>
                      <div className='shadow-md w-full rounded-xl'>
                        <img
                          className="rounded-xl object-cover"
                          src={prod.medias.length ? prod.medias[0].media : p1}
                          alt="fairdeal product"
                          style={{ height: "160px", width: "100%", objectFit: "initial" }}
                        />
                        <div className=" ml-1 absolute border-1  w-[15.5rem] top-[16rem] lg:top-[28rem] rounded-full text-center bg-orange-800/75 ">
                          <small color="white" className="font-bold">
                            Special mk kn kn
                          </small>
                        </div>
                      </div>
                      <div className="mt-2.5 flex justify-between">
                        <small
                          color="blue-gray"
                          className="flex w-full items-center gap-1 ml-1.5"
                        >
                          5.0
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="-mt-0.5 h-5 w-5 text-yellow-700"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </small>
                        <div className="flex font-bold text-gray-800 items-center">
                          <BsCurrencyRupee />{prod.price}
                        </div>
                        
                      </div>
                      <div className="mt-2.5 flex items-center justify-between">
                        <small variant="" color="blue-gray" className="font-medium">
                          {prod.pTitle}
                        </small>
                      </div>
                      <div className='mt-2.5 gap-x-2'>
                        <button
                          type="submit"
                          className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  })
                  : 
                  <div className="ml-2 p-2.5 shadow-md rounded-xl my-5">
                    <div className='shadow-md w-full rounded-xl'>
                      <img
                        className="rounded-xl object-cover object-center"
                        src={p1}
                        alt="fairdeal product"
                      />
                        <div className=" text-center text-white mt-1 mx-1 rounded-t-lg rounded-b-md bg-orange-800/75 px-2">
                          <small color="white" className="font-bold">
                            Offer: Promocode
                          </small>
                        </div>
                        <IconButton
                        size="sm"
                        color="red"
                        variant="text"
                        className="!absolute top-4 right-4 rounded-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-6 w-6"
                        >
                          <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                        </svg>
                        </IconButton>
                    </div>
                      <div className="mt-2.5 grid grid-cols-2">
                      <small
                        color="blue-gray"
                        className="flex w-full items-center gap-1 ml-1.5"
                      >
                        5.0
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="-mt-0.5 h-5 w-5 text-yellow-700"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </small>
                      <div className="flex w-full">
                        <Tooltip content="Free wifi">
                          <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </Tooltip>
                        <Tooltip content={`65" HDTV`}>
                          <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="h-5 w-5"
                            >
                              <path d="M19.5 6h-15v9h15V6z" />
                              <path
                                fillRule="evenodd"
                                d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </Tooltip>
                        <Tooltip content="Fire alert">
                          <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </Tooltip>
                        <span className='ml-1 mt-1'>+2</span>
                      </div>
                      </div>
                      <div className="mt-2.5 flex items-center justify-between">
                        <small variant="" color="blue-gray" className="font-medium">
                            Dazzling Gold and Kundan Necklace Set
                        </small>
                      </div>
                      <div className="mt-2.5 font-bold text-gray-800 flex items-center ">
                        <BsCurrencyRupee/>1,500
                        <div>
                          <sub>
                            <del className='text-gray-500'><BsCurrencyRupee style={{float:'left', marginTop:'7px'}}/>2999</del>
                          <span className='text-green-600'> 70% Off</span>
                          </sub>
                        </div>
                        <button type="button" className="mx-auto rounded-lg text-white p-2 border-2 shadow-md bg-gradient-to-r from-cyan-500 to-blue-500">
                          {/* <span className='mx-auto'><BsHandbagFill/></span> */}
                          {/* {x.inCart ? "Remove from" : "Add to"} Bag */}
                          Add to Bag
                        </button>
                      </div>
                      <div className='mt-2.5 gap-x-2'>
                          {/* <button
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-blue-500 to-cyan-500  py-3 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                          >
                            Add to bag
                          </button> */}
                          <button
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                          >
                            Buy Now
                          </button>
                        </div>
                  </div>
                  }

              </div>
              {/* </div> */}
            </section>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default AllProducts

import { useEffect, useState } from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { RadioGroup,Popover } from '@headlessui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import p1 from '../img/ktytyj.webp';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { doc, getDoc } from 'firebase/firestore';
import { dataBase } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import { BsCurrencyRupee } from 'react-icons/bs';
import { CartState } from '../context/Context';

const product = {
  name: 'Basic Tee 6-Pack',
  price: '₹192',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Men', href: '#' },
    { id: 2, name: 'Clothing', href: '#' },
  ],
  images: [
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg',
      alt: 'Two each of gray, white, and black shirts laying flat.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg',
      alt: 'Model wearing plain black basic tee.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg',
      alt: 'Model wearing plain gray basic tee.',
    },
    {
      src: 'https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg',
      alt: 'Model wearing plain white basic tee.',
    },
  ],
  description:
    'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
  highlights: [
    'Hand cut and sewn locally',
    'Dyed with our proprietary colors',
    'Pre-washed & pre-shrunk',
    'Ultra-soft 100% cotton',
  ],
  offers: [
    { title: 'Bank Offer', details: '10% off on Axis Bank Credit Card and EMI Transactions, up to ₹1500, on orders of ₹5,000 and above', description:'lorem ipsum for testlorem ipsum for testlorem ipsum for testlorem ipsum for test' },
    { title: 'Upi Offer', details: 'Flat ₹1,250 Off on HDFC Bank Credit Card EMI Trxns on orders priced between ₹15,000 to ₹39,999', description:'lorem ipsum for testlorem ipsum for testlorem ipsum for testlorem ipsum for test' },
    { title: 'Credit Card Offer', details: 'Extra ₹500 Off on Bikes & Scooters on purchase of ₹30,000 or more', description:'lorem ipsum for testlorem ipsum for testlorem ipsum for testlorem ipsum for test' },
  ],
  details:
    'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
}
const reviews = { href: '#rr', average: 4, totalCount: 117 }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SingleProducts = () => {
  const [selectedColor, setSelectedColor] = useState()
  const [selectedSize, setSelectedSize] = useState()
  const params = useParams();
  const [productData, setProductData] = useState({});
  const cartState = CartState();

  const fetchById = async (id) => {
    const docRef = doc(dataBase, "products", id);
    try {
      if (docRef) {
        return await getDoc(docRef)
          .then(res => {
            console.log(res.data());
            const resData = res.data();
            
            if (cartState) {
              if (cartState.cart.length) {
                const localCartIds = cartState.cart.map(eachItem => eachItem.id);
                resData.inCart = localCartIds.includes(productData.id);
              }
              
            }
            setProductData({
              ...resData
            });
          })
          .catch(err => {
            console.log("err at details ", err);
          });
      } else {
        console.log("No data found");
      }

    } catch (err) {
      console.log(err);
    }
  }

  
  const addProductToBag = (prod, increase) => {
    if (cartState) {
      cartState.updateCart({...prod,selectedColor,
        selectedSize,}, increase);
      setProductData({
        ...productData,
        inCart: increase
      });
    }
  };

  useEffect(() => {
    console.log("cartState ",cartState)
    if (cartState) {
      if (cartState.cart.length) {
        const localCartIds = cartState.cart.map(eachItem => eachItem.id);
        const localProd = {...productData};
        localProd.inCart = localCartIds.includes(productData.id);
        setProductData({...localProd});
      }
      
    }
  }, [cartState, cartState.length]);

  useEffect(() => {
    if (params?.id) {
      fetchById(params.id);
    } 
  }, []);

  return (
    <>
    <Header/>
    <div className="bg-white">
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 lg:max-w-7xl lg:px-8">
            {product.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product.name}
              </a>
            </li>
          </ol>
        </nav>
        {/* Product info */}
      <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
        <div className='desk-view'>
             <Swiper
                spaceBetween={30}
                effect={'fade'}
                // navigation={true}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Navigation, Pagination]}
                className=" rounded-md"
              >
              {productData?.medias?.length ?
              productData.medias.map((imgData, ind) => {
                return <SwiperSlide key={`image-${ind}`}>
                <img src={imgData.media} />
              </SwiperSlide>
              }) : <SwiperSlide>
              <img src={p1} />
            </SwiperSlide>}
              
            </Swiper>
            <div className='gap-x-2 grid  grid-cols-2 mb-4'>
            <button
                type="submit"
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => addProductToBag(productData, !productData.inCart)}
              >
                {productData.inCart ? "Remove from" : "Add to"} Bag
              </button>
              <button
                type="submit"
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-lime-600 px-8 py-3 text-base font-medium text-white hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Buy Now
              </button>
              </div>
        </div>

          <div className="lg:col-span-2 lg:pr-8">
            <h1 className="text-xl font-bold tracking-tight text-gray-900">{productData.pTitle}</h1>
            {/* Reviews */}
          <div className="mt-2">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  <a href={reviews.href}>
                <span className="inline-flex items-center rounded bg-green-700 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
                &nbsp;4.9&nbsp;<StarIcon className='text-white mb-1 w-3 flex-shrink-0'/>
                </span></a>
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating ? 'text-lime-600' : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.totalCount} reviews
                </a>
              </div>
            </div>
          {/* Reviews */}
            {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
            <p className='mt-2 text-2xl tracking-tight text-gray-900 flex items-center'><BsCurrencyRupee />{productData.price}</p>
            <div className="mt-2">
              <h3 className="text-sm font-medium text-gray-900">Available Offers</h3>

              <div className="mt-4">
                <ul role="list" className=" space-y-2 pl-4 text-sm">
                  {product.offers.map((offer) => (
                    <li key={offer.title} className="text-gray-400">
                      <span className="text-gray-600"><span className='font-bold'>{offer.title}:</span> {offer.details}</span>
                      {/* <span className='text-blue-500 counter'> T&C</span> */}
                      {/* <div className="msg">Praajak has 202 collective groups across different districts of West Bengal.</div> */}
                      <div className="tooltip text-blue-500 "> T&C
                        <span className="tooltiptext">{offer.description}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            
            {/* Image gallery */}
        {/* <div className="mobile-view mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={product.images[0].src}
              alt={product.images[0].alt}
              className="h-full w-full object-cover object-center rounded-md"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.images[1].src}
                alt={product.images[1].alt}
                className="h-full w-full object-cover object-center rounded-md"
              />
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.images[2].src}
                alt={product.images[2].alt}
                className="h-full w-full object-cover object-center rounded-md"
              />
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <img
              src={product.images[3].src}
              alt={product.images[3].alt}
              className="h-full w-full object-cover object-center rounded-md"
            />
          </div>
        </div> */}
            
            <form className="mt-4">
              {/* Colors */}
              <div>
                <h3 className="text-sm font-medium text-gray-900">Color</h3>

                <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                  <div className="flex items-center space-x-3">
                    {productData.colors && Object.keys(productData.colors)?.map((color) => (
                      <RadioGroup.Option
                        key={color}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedClass,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                          )
                        }
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            'h-8 w-8 rounded-full border border-black border-opacity-10'
                          )}
                          style={{
                            backgroundColor: color
                          }}
                        />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              {/* Sizes */}
              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Storage</h3>
                  
                </div>

                <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a storage</RadioGroup.Label>
                  <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                    {productData.storages &&  Object.keys(productData.storages).map((size) => (
                      <RadioGroup.Option
                        key={size}
                        value={size}
                        className={({ active }) =>
                          classNames(
                            active ? 'ring-2 ring-indigo-500' : '',
                            'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">{size}</RadioGroup.Label>
                              <span
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-indigo-500' : 'border-transparent',
                                  'pointer-events-none absolute -inset-px rounded-md'
                                )}
                                aria-hidden="true"
                              />
                            
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              {/* <button
                type="submit"
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Add to bag
              </button> */}
            </form>
          </div>
          {/* Options */}
          {/* highlights */}
          <div className="mt-4">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  {productData.connectivities &&  Object.keys(productData.connectivities).map((highlight) => (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          {/* Description and details */}
            <div className='mt-4'>
                  <h3 className="text-sm font-medium text-gray-900">Description</h3>
                  <div className="mt-4 space-y-6">
                    <p className="text-base text-gray-900">{productData.pDescription}</p>
                  </div>
            </div>
            {/* Description and details */}
            {/* details */}
            <div className="mt-4">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">Software Description: {productData.softwareDescription}</p>
                <p className="text-sm text-gray-600">Hardware Description: {productData.hardwareDescription}</p>
              </div>
            </div>
            {/* revie&rating */}
            <div className="flex items-center mt-4" id="rr">
              <h2 className="text-sm font-medium text-gray-900">Ratings & Reviews<br/><br/><br/><br/></h2>
              
              <p className=" text-sm font-medium text-gray-900 dark:text-white">4.95 out of 5</p>
                <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                <svg className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                <svg className="w-4 h-4 text-gray-300 mr-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                
            </div>
            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">1,745 global ratings</p>
            <div className="flex items-center mt-4">
                <a href="#" className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">5 star</a>
                <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                    <div className="h-5 bg-yellow-300 rounded" style={{width: '70%'}}></div>
                </div>
                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">70%</span>
            </div>
            <div className="flex items-center mt-4">
                <a href="#" className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">4 star</a>
                <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                    <div className="h-5 bg-yellow-300 rounded" style={{width: '17%'}}></div>
                </div>
                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">17%</span>
            </div>
            <div className="flex items-center mt-4">
                <a href="#" className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">3 star</a>
                <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                    <div className="h-5 bg-yellow-300 rounded" style={{width: '8%'}}></div>
                </div>
                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">8%</span>
            </div>
            <div className="flex items-center mt-4">
                <a href="#" className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">2 star</a>
                <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                    <div className="h-5 bg-yellow-300 rounded" style={{width: '4%'}}></div>
                </div>
                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">4%</span>
            </div>
            <div className="flex items-center mt-4">
                <a href="#" className="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">1 star</a>
                <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                    <div className="h-5 bg-yellow-300 rounded" style={{width: '1%'}}></div>
                </div>
                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">1%</span>
            </div>   

          </div>
          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:pb-16 lg:pr-8 lg:pt-6">
            other related products
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}
export default SingleProducts
import { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition, Popover } from '@headlessui/react';
import f_logo from '../assets/images/logo/ratna_logo.png';
import { BsHandbagFill,BsTrash3Fill,BsCurrencyRupee} from 'react-icons/bs';
import Cart from '../pages/Cart';
import {
  List,
  ListItem,
  ListItemSuffix,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { CartState } from '../context/Context';
// import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {LoginModal} from './LoginModal';
import '../assets/css/style.css';
import {
  ArrowPathIcon,
  Bars3Icon,XMarkIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';

const solutions = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: "Your customers' data will be safe and secure", href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Products', href: '/Allproducts', current: false },
  { name: 'About', href: '/about', current: false },
  { name: 'B2B', href: '/b2b', current: false },
  
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const [ loggedIn, setloggedIn] = useState(0);
  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.removeItem('Token');
    sessionStorage.removeItem('uName');
    sessionStorage.removeItem('uid');
    sessionStorage.removeItem('mailid');
    sessionStorage.removeItem('dp');

    navigate('/')
  }
  useEffect(() => {
    let token = sessionStorage.getItem('Token')
    if(token){
      setloggedIn(1)
    }
  },)
  const cartState = CartState();
  const [productList, updateProductList] = useState([]);
  const [subTotal, updateSubTotal] = useState(0);

  useEffect(() => {
    if (cartState) {
      updateProductList(cartState.cart);
      if (cartState.cart?.length) {
        let count = 0;
        cartState.cart.forEach(element => {
          count += Number(element.price);
        });
        updateSubTotal(count);
      }
    }
  }, [cartState]);

  const removeCartItem = (item) => {
    cartState.updateCart(item, false);
  }
  let dp = sessionStorage.getItem('dp')
  return (
    <Disclosure as="nav" className="">
      
      {({ open }) => (
        <>
            <div className="container mx-auto header-top_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ht-left_area">
                                <div className="header-shipping_area">
                                    <ul>
                                        <li>
                                            <span>Telephone Enquiry:</span>
                                            <a href="callto://+123123321345">(+123) 123 321 345</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ht-right_area">
                                <div className="ht-menu">
                                    {/* <ul>
                                        <li><a href="javascript:void(0)">Currency<i className="fa fa-chevron-down"></i></a>
                                            <ul className="ht-dropdown ht-currency">
                                                <li><a href="javascript:void(0)">€ EUR</a></li>
                                                <li className="active"><a href="javascript:void(0)">£ Pound Sterling</a></li>
                                                <li><a href="javascript:void(0)">$ Us Dollar</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="javascript:void(0)">Language <i className="fa fa-chevron-down"></i></a>
                                            <ul className="ht-dropdown">
                                                <li className="active"><a href="javascript:void(0)"><img src="assets/images/menu/icon/1.jpg" alt="JB's Language Icon">English</a></li>
                                                <li><a href="javascript:void(0)"><img src="assets/images/menu/icon/2.jpg" alt="JB's Language Icon">Français</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a href="my-account.html">My Account<i className="fa fa-chevron-down"></i></a>
                                            <ul className="ht-dropdown ht-my_account">
                                                <li><a href="javascript:void(0)">Register</a></li>
                                                <li className="active"><a href="javascript:void(0)">Login</a></li>
                                            </ul>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#cda557]'>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href='/'>
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src={f_logo}
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src={f_logo}
                    alt="Your Company"
                  /></a>                  
                </div>
                <div className="w-auto hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-white hover:bg-gray-50',
                          'px-3 py-2 text-lg font-bold hover:border-gray-500 hover:border-r-4 hover:border-l-4'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="w-auto hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                  <Popover className="relative">
                    <Popover.Button className="text-white hover:text-[#cca85a] hover:bg-gray-50 hover:border-gray-500 hover:border-r-4 hover:border-l-4 px-3 py-2 text-lg font-bold">
                      <span >Solutions</span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                          <div className="p-4">
                            {solutions.map((item) => (
                              <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                  <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                </div>
                                <div>
                                  <a href={item.href} className="font-semibold text-gray-900">
                                    {item.name}
                                    <span className="absolute inset-0" />
                                  </a>
                                  <p className="mt-1 text-gray-600">{item.description}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                            {callsToAction.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                              >
                                <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                {item.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                  </div>
                </div>
                
                <div className="w-full hidden h-8 w-auto lg:block">
                  <input className="placeholder:italic placeholder:text-slate-400 w-full block bg-white border border-slate-300 rounded-md py-2 pl-4 ml-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search"/>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="  mr-4 mt-1">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        {productList.length ?
                        <Popover.Button>
                          <span className='bg-gray-800 pt-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                            <BsHandbagFill className="h-7 w-7" aria-hidden="true" onClick={() => {<Cart />}}/></span>
                        </Popover.Button> : 
                        <span className='bg-gray-800 pt-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                        <BsHandbagFill className="h-7 w-7" aria-hidden="true" onClick={() => {<Cart />}}/></span>}
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute right-1/4 z-10 mt-3 w-screen max-w-sm  sm:px-0 ">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              {/* <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2"> */}
                              <Card className="w-96">
                              <List>
{/* <<<<<<< HEAD
                                {cartProduct.map((item) => (
                                    <ListItem ripple={false} className="py-1 pr-1 pl-4" key={item.name}>
                                      <div className='grid grid-rows-3 grid-flow-col'>
======= */}
                                {productList.map((item) => (
                                    <ListItem ripple={false} className="py-1 pr-1 pl-4" key={item.title}>
                                      <div className='grid grid-rows-3 grid-flow-col gap-4'>
{/* >>>>>>> 914437a256e123d9396ef2087ddb2e1ed04d7490 */}
                                        <div className='row-span-3'>
                                          <img src={item.medias?.[0]?.media || f_logo} className='w-16' alt={item.title}/>  
                                        </div>                                      
                                        <div className='col-span-2'>
{/* <<<<<<< HEAD
                                          {item.name}
                                          <div className='flex flex-row'>
                                            <div>
                                              <IconButton variant="text" className='border-2' onClick={deNumber}>-</IconButton>
                                            </div>
                                            <div className='border-2 rounded mx-1 px-2 content-center'>{number}</div>
                                            <div>
                                              <IconButton variant="text" className='border-2' onClick={inNumber}>+</IconButton>
                                            </div>
                                          </div>
======= */}
                                        {item.pTitle}
{/* >>>>>>> 914437a256e123d9396ef2087ddb2e1ed04d7490 */}
                                        </div>
                                        <div className='row-span-2 col-span-2 flex items-center'>
                                          <BsCurrencyRupee/>{item.price}
                                        </div>
                                      </div>
                                      <ListItemSuffix>
                                        <IconButton variant="text" className='border-2' color="blue-gray" onClick={() => removeCartItem(item)}>
                                          <BsTrash3Fill />
                                        </IconButton>
                                      </ListItemSuffix>
                                    </ListItem>
                                ))}
                                </List>
                                </Card>
                              {/* </div> */}
                              <div className="flex p-4 border-top-2 justify-between">
                                  <span>Subtotal:</span>
                                  <span className='flex align-center'><BsCurrencyRupee/> {subTotal}</span>
                              </div>
                              <div className="bg-gray-50 p-4">
                                  <button
                                    type="button"
                                    className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                                  >
                                    Checkout
                                  </button>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  
                      {loggedIn?(
                        <>
                        <div>
                            <span className="sr-only">Open user menu</span>
                            <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <img
                            className="h-8 w-8 rounded-full"
                            src={dp}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/profile"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                Your Profile
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/purchaseHistory"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                Purchase History
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                onClick={logout}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700','cursor-pointer')}
                              >
                                Sign out
                              </span>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                        </>
                       ):(
                      <>                    
                              <LoginModal/>
                      </>
                      )}
                </Menu>
              </div>
            </div>
          </div>
          </div>
          <div className="block h-12 pr-4 lg:hidden">
                  <input className="placeholder:italic placeholder:text-slate-400 w-full block bg-white border border-slate-300 rounded-md py-2 pl-4 ml-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search"/>
                </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
export default Header
import { Footer } from 'flowbite-react';
import { BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';
import {FaMapMarkerAlt} from "react-icons/fa";
import r_logo from '../assets/images/logo/ratna_logo.png';
import { MdEmail } from "react-icons/md";

export default function FooterSitemapLinks() {
  return (
    <Footer className='bg-gradient-to-r from-footer-blue to-footer-red' style={{borderTopLeftRadius: '95px', borderTopRightRadius:'0px', borderBottomLeftRadius:'0', borderBottomRightRadius:'0'}}>
      <div className="w-full">
        <div className="grid w-full gap-6 px-6 md:px-24 py-12 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
        <div className="">
                            <div className="footer-widgets_info">

                                <div class="footer-widgets_logo">
                                    <a href="#">
                                        <img src={r_logo} alt="Hiraola's Footer Logo"/>
                                    </a>
                                </div>

                                <div class="widget-short_desc">
                                    <p className='text-white'>We are a team of designers and developers that create high quality HTML Template &amp;
                                        Woocommerce, Shopify Theme.
                                    </p>
                                </div>
                                <div class="hiraola-social_link">
                                <div className="mt-4 flex space-x-6 sm:mt-0">
                                    <div className='rounded border border-[#cca85a] p-1'>
                                    <Footer.Icon
                                      className='text-white '
                                      href="#"
                                      icon={BsFacebook}
                                    />
                                    </div>
                                    <div className='rounded border border-[#cca85a] p-1'>
                                    <Footer.Icon
                                      className='text-white '
                                      href="#"
                                      icon={BsInstagram}
                                    />
                                    </div>
                                    <div className='rounded border border-[#cca85a] p-1'>
                                    <Footer.Icon
                                      className='text-white '
                                      href="#"
                                      icon={BsTwitter}
                                    />
                                    </div>
                                    <div className='rounded border border-[#cca85a] p-1'>
                                    <Footer.Icon
                                      className='text-white '
                                      href="#"
                                      icon={BsGithub}
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
          <div>
          <div class="col-lg-3">
                                        <div class="footer-widgets_title mb-4">
                                            <h6 className='text-[#cca85a] hover:text-white font-bold text-lg'>Product</h6>
                                        </div>
                                        <div class="footer-widgets">
                                            <ul className=' '>
                                                <li className='mb-3 text-lg'><a href="#" className='text-[#999] hover:text-[#cca85a]'>Prices drop</a></li>
                                                <li className='mb-3 text-lg'><a href="#" className='text-[#999] hover:text-[#cca85a]'>New products</a></li>
                                                <li className='mb-3 text-lg'><a href="#" className='text-[#999] hover:text-[#cca85a]'>Best sales</a></li>
                                                <li className='mb-3 text-lg'><a href="#" className='text-[#999] hover:text-[#cca85a]'>Contact us</a></li>
                                            </ul>
                                        </div>
                                    </div>
          </div>
          <div>
          <div class="col-lg-5">
                                        <div class="footer-widgets_info">
                                            <div class="footer-widgets_title mb-4">
                                                <h6 className='text-[#cca85a] hover:text-white font-bold text-lg'>About Us</h6>
                                            </div>
                                            <div class="widgets-essential_stuff">
                                                <ul className='text-lg'>
                                                    <li class="hiraola-address" className='text-[#999] hover:text-[#cca85a]'><span className='text-[#999]'>Address:</span> The Barn,
                                                        Ullenhall, Henley
                                                        in
                                                        Arden B578 5CC, England</li>
                                                    <li class="hiraola-phone" ><i class="ion-ios-telephone"></i><span className='text-[#999]'>Call
                                                    Us:</span> <a href="tel://+123123321345" className='text-[#999] hover:text-[#cca85a]'>+123 321 345</a>
                                                    </li>
                                                    <li class="hiraola-email"><i class="ion-android-mail"></i><span className='text-[#999]'>Email:</span> <a href="mailto://info@yourdomain.com" className='text-[#999] hover:text-[#cca85a]'>info@yourdomain.com</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
          </div>
          <div class="col-lg-4">
                                        <div class="instagram-container footer-widgets_area">
                                            <div class="footer-widgets_title mb-4">
                                                <h6 className='text-[#cca85a] hover:text-white font-bold text-lg'>Sign Up For Newslatter</h6>
                                            </div>
                                            <div class="widget-short_desc mb-4">
                                                <p className='text-[#999] text-lg'>Subscribe to our newsletters now and stay up-to-date with new collections</p>
                                            </div>
                                            <div class="newsletter-form_wrap">
                                                <form class="subscribe-form" id="mc-form" action="#" novalidate="true">
                                                    <input class="newsletter-input" id="mc-email" type="email" autocomplete="off" name="EMAIL" value="Enter Your Email" />
                                                    <button class="newsletter-btn" id="mc-submit">
                                                      <MdEmail className='newsletter-btn-icon'/>
                                                    </button>
                                                </form>
                                                <div class="mailchimp-alerts mt-3">
                                                    <div class="mailchimp-submitting"></div>
                                                    <div class="mailchimp-success"></div>
                                                    <div class="mailchimp-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        </div>
        <hr className='md:mx-10 mx-6'></hr>
        <div className="w-full px-6 md:px-10 py-6 flex items-center justify-between">
        <div class="footer-bottom_area">
                <div class="container">
                    <div class="footer-bottom_nav">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="footer-links">
                                    <ul>
                                        <li><a href="#">Online Shopping</a></li>
                                        <li><a href="#">Promotions</a></li>
                                        <li><a href="#">My Orders</a></li>
                                        <li><a href="#">Help</a></li>
                                        <li><a href="#">Customer Service</a></li>
                                        <li><a href="#">Support</a></li>
                                        <li><a href="#">Most Populars</a></li>
                                        <li><a href="#">New Arrivals</a></li>
                                        <li><a href="#">Special Products</a></li>
                                        <li><a href="#">Manufacturers</a></li>
                                        <li><a href="#">Our Stores</a></li>
                                        <li><a href="#">Shipping</a></li>
                                        <li><a href="#">Payments</a></li>
                                        <li><a href="#">Warantee</a></li>
                                        <li><a href="#">Refunds</a></li>
                                        <li><a href="#">Checkout</a></li>
                                        <li><a href="#">Discount</a></li>
                                        <li><a href="#">Refunds</a></li>
                                        <li><a href="#">Policy Shipping</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="copyright">
                                    <span>Copyright © {(new Date().getFullYear())} <a href="index.html">Ratna.</a> All rights reserved.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Footer>
  )
}

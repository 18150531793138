import { createContext, useContext, useReducer, useState } from 'react'

const Cart = createContext();

const Context = ({ children }) => {
  
  const [cart, updateCartState] = useState([]);

  const updateCart = (newVal, increasing) => {
    if (!cart.length) {
      updateCartState([{...newVal, quantity: 1}]);
    } else {
      const existingItem = cart.filter(eachItem => eachItem.id === newVal.id);
      if (existingItem.length) {
        let localItem = {...existingItem[0], quantity: increasing ? existingItem[0].quantity + 1 : existingItem[0].quantity - 1};
        if (!localItem.quantity) {
          localItem = null;
        }
        updateCartState(cart.map(eachItem => {
          if (eachItem.id === newVal.id && !localItem) {
            return localItem;
          } else {
            return {...eachItem};
          }
        }).filter(eachItem => eachItem !== null));
      } else {
        updateCartState([...cart, {...newVal, quantity: 1}]);
      }
    }
  };

  return (
    <Cart.Provider value={{cart, updateCart}}>{children}</Cart.Provider>
  );
};

export default Context;

export const CartState = () => {
  return useContext(Cart);
}
export const products = [{
    id: "12",
    title: "Boat",
    price: 12
}, {
    id: "34",
    title: "Sony",
    price: 34
}, {
    id: "56",
    title: "Bose",
    price: 56
}, {
    id: "78",
    title: "Hp",
    price: 78
}];
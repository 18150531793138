export const colors = [{
    label: "Red",
    value: "#ff0000"
  }, {
    label: "Green",
    value: "#00ff00"
  }, {
    label: "Blue",
    value: "#0000ff"
  }, {
    label: "Black",
    value: "#000000"
  }, {
    label: "White",
    value: "#ffffff"
  },];
  export const storages = [{
    label: "32 GB",
    value: "32GB"
  }, {
    label: "64 GB",
    value: "64GB"
  }];
  export const connectivities = [{
    label: "WIFI",
    value: "WIFI"
  }, {
    label: "Bluetooth",
    value: "BLUETOOTH"
  }];
import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import banner1 from '../img/jbanner1.png';
import banner2 from '../img/jbanner4.jpg';
import banner3 from '../img/jbanner3.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
// import Carousel from "react-multi-carousel";
import ProCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import p1 from '../img/ktytyj.webp';
import { CartState } from '../context/Context'
import { BsCurrencyRupee} from 'react-icons/bs'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import { products } from '../config/dummy';
import '../assets/css/style.css';
import bnrimg from '../img/16774.jpg';
import demo_banner from '../img/demo_banner.jpg';
import { ChevronRightIcon, } from '@heroicons/react/20/solid'

const data = [
  {
    label: "NECKLACES",
    value: "html",
    desc: `It really matters and then like it really doesn't matter.
    What matters is the people who are sparked by it. And the people 
    who are like offended by it, it doesn't matter.`,
  },
  {
    label: "EARRINGS",
    value: "react",
    desc: `Because it's about motivating the doers. Because I'm here
    to follow my dreams and inspire other people to follow their dreams, too.`,
  },
  {
    label: "BRACELET",
    value: "vue",
    desc: `We're not always in the position that we want to be at.
    We're constantly growing. We're constantly making mistakes. We're
    constantly trying to express ourselves and actualize our dreams.`,
  },
  {
    label: "ANKLET",
    value: "angular",
    desc: `Because it's about motivating the doers. Because I'm here
    to follow my dreams and inspire other people to follow their dreams, too.`,
  },
];
const Home = () => {

  const cartState = CartState();
  const [productList, updateProductList] = useState([...products]);
  const [initialLoad, updateInitialLoad] = useState(true);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      // partialVisibilityGutter: 80
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6.5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3.8
    }
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6.5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
      // partialVisibilityGutter: 80
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2.6
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.3,
    }
  };
  // const catImg = [
  //   { imageSrc: catimg1, imageAlt: 'fairdeal catagory brand boat', link:'/Allproducts', },
  //   { imageSrc: catimg2, imageAlt: 'fairdeal catagory brand lenovo', link:'/Allproducts',},
  //   { imageSrc: catimg3, imageAlt: 'fairdeal catagory brand jbl',link:'/Allproducts', },
  //   { imageSrc: catimg1, imageAlt: 'fairdeal catagory brand boat',link:'/Allproducts', },
  //   { imageSrc: catimg2, imageAlt: 'fairdeal catagory brand lenovo',link:'/Allproducts', },
  //   { imageSrc: catimg3, imageAlt: 'fairdeal catagory brand jbl',link:'/Allproducts', },
  //   { imageSrc: catimg1, imageAlt: 'fairdeal catagory brand boat',link:'/Allproducts', },
  //   { imageSrc: catimg2, imageAlt: 'fairdeal catagory brand lenovo',link:'/Allproducts', },
  //   { imageSrc: catimg3, imageAlt: 'fairdeal catagory brand jbl',link:'/Allproducts', },
  // ];

  const addProductToBag = (prod, increase) => {
    if (cartState) {
      cartState.updateCart(prod, increase);
      updateProductList(productList.map(eachProd => {
        if (eachProd.id === prod.id) {
          return {
            ...eachProd,
            inCart: increase
          }
        } else {
          return {...eachProd};
        }
      }));
    }
  };

  useEffect(() => {
    console.log("cartState ",cartState)
    if (cartState) {
      if (cartState.cart.length) {
        const localCartIds = cartState.cart.map(eachItem => eachItem.id);
        const localProds = [...productList];
        localProds.forEach(each => {
          // need to modify if multiple can be added to cart
          each.inCart = localCartIds.includes(each.id);
        });
        updateProductList([...localProds]);
      } else {
        updateProductList([...products]);
      }
      
    }
  }, [cartState, cartState.length]);

  return (
    <>
    <Header/>
    <div class="grid grid-cols-4 gap-4 p-8">
    <div className="">
      <div class="col order-md-2 order-lg-1">
                        <div class="category-menu">
                            <div class="category-heading">
                                <h2 class="categories-toggle"><span>Shop by categories</span></h2>
                            </div>
                            <div id="cate-toggle" class="category-menu-list">
                                <ul>
                                    <li class="right-menu"><a href="#">Hand Harness</a>
                                        <ul class="cat-mega-menu">
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Habitant Morbi</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Habitant Morbi</a></li>
                                                    <li><a href="shop-left-sidebar.html">Office chair</a></li>
                                                    <li><a href="shop-left-sidebar.html">Purus Lacus</a></li>
                                                    <li><a href="shop-left-sidebar.html">Sagittis Blandit</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Purus Lacus</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Magna Pellentesq</a></li>
                                                    <li><a href="shop-left-sidebar.html">Molestie Tortor</a></li>
                                                    <li><a href="shop-left-sidebar.html">Vehicula Element</a></li>
                                                    <li><a href="shop-left-sidebar.html">Volutpat Diam</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Office chair</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Bibendum Cursus</a></li>
                                                    <li><a href="shop-left-sidebar.html">Dignissim Turpis</a></li>
                                                    <li><a href="shop-left-sidebar.html">Dining room</a></li>
                                                    <li><a href="shop-left-sidebar.html">Egestas Ultricies</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="right-menu"><a href="#">Maang Tika</a>
                                        <ul class="cat-mega-menu cat-mega-menu-2">
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Cameras</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Cords and Cables</a></li>
                                                    <li><a href="shop-left-sidebar.html">gps accessories</a></li>
                                                    <li><a href="shop-left-sidebar.html">Microphones</a></li>
                                                    <li><a href="shop-left-sidebar.html">Wireless Transmitters</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Audio</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Other Accessories</a></li>
                                                    <li><a href="shop-left-sidebar.html">Portable Audio</a></li>
                                                    <li><a href="shop-left-sidebar.html">Satellite Radio</a></li>
                                                    <li><a href="shop-left-sidebar.html">Visual Accessories</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Cell Phones</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Xail</a></li>
                                                    <li><a href="shop-left-sidebar.html">Chamcham Galaxy</a></li>
                                                    <li><a href="shop-left-sidebar.html">SIM Cards</a></li>
                                                    <li><a href="shop-left-sidebar.html">Prepaid Cell Phones</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">TV &amp; Video</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">4K Ultra HDTVs</a></li>
                                                    <li><a href="shop-left-sidebar.html">All TVs</a></li>
                                                    <li><a href="shop-left-sidebar.html">Refurbished TVs</a></li>
                                                    <li><a href="shop-left-sidebar.html">TV Accessories</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="right-menu"><a href="#">Toe Rings</a>
                                        <ul class="cat-mega-menu cat-mega-menu-3">
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Sagittis Blandit</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">adipiscing elit</a></li>
                                                    <li><a href="shop-left-sidebar.html">consectetur adip</a></li>
                                                    <li><a href="shop-left-sidebar.html">erat et est</a></li>
                                                    <li><a href="shop-left-sidebar.html">molestie velit</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Accessories</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Habitant Morbi</a></li>
                                                    <li><a href="shop-left-sidebar.html">Office chair</a></li>
                                                    <li><a href="shop-left-sidebar.html">Purus Lacus</a></li>
                                                    <li><a href="shop-left-sidebar.html">Sagittis Blandit</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="right-menu"><a href="#">Traditional Earrings</a>
                                        <ul class="cat-mega-menu">
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Accessories</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Habitant Morbi</a></li>
                                                    <li><a href="shop-left-sidebar.html">Office chair</a></li>
                                                    <li><a href="shop-left-sidebar.html">Purus Lacus</a></li>
                                                    <li><a href="shop-left-sidebar.html">Sagittis Blandit</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Purus Lacus</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Magna Pellentesq</a></li>
                                                    <li><a href="shop-left-sidebar.html">Molestie Tortor</a></li>
                                                    <li><a href="shop-left-sidebar.html">Vehicula Element</a></li>
                                                    <li><a href="shop-left-sidebar.html">Volutpat Diam</a></li>
                                                </ul>
                                            </li>
                                            <li class="right-menu cat-mega-title">
                                                <a href="#">Office chair</a>
                                                <ul>
                                                    <li><a href="shop-left-sidebar.html">Bibendum Cursus</a></li>
                                                    <li><a href="shop-left-sidebar.html">Dignissim Turpis</a></li>
                                                    <li><a href="shop-left-sidebar.html">Dining room</a></li>
                                                    <li><a href="shop-left-sidebar.html">Egestas Ultricies</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Kada Cum Bracelet</a></li>
                                    <li><a href="#">Exquisite Rings</a></li>
                                    <li><a href="#">Necklaces</a></li>
                                    <li class="rx-child"><a href="#">Anklet – Epitome Of Culture</a></li>
                                    <li class="rx-child"><a href="#">Graceful Armlet</a></li>
                                    <li class="rx-parent">
                                        <a class="rx-default">More Categories</a>
                                        <a class="rx-show">Collapse</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
      </div>
    </div>
    {/* slider */}
    <div className='col-span-2 ...'>
            <Swiper
                spaceBetween={30}
                // effect={'fade'}
                navigation={true}
                autoplay={300}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Navigation, Pagination]}
            >
              <SwiperSlide>
                <img  src={bnrimg} alt="fairdeal banner"/>
              </SwiperSlide>
              <SwiperSlide>
                <img  src={banner1} alt='fairdeal banner2' />
              </SwiperSlide>
            </Swiper>
    </div>
    {/* slider */}
    <div>
      <img src={banner3} alt="Side_banner"/>
    </div>
    </div>
    <div class="grid grid-cols-3 mx-auto gap-4 p-8">
      <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
      <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
      <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
    </div>
    
    <div className='mx-0 md:mx-5 px-12'>
      {/* products */}
        <div className='grid grid-cols-2 gap-5 bg-[#cca85a59] rounded-lg p-4'>
          <div><span className='pb-2 text-lg font-bold border-b-2 border-[#cda557]'>NEW ARRIVAL</span>
          </div>
          <div>
          <p className='bg-[#cda557] rounded-full border float-right text-white'>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </p>
          </div>
        </div>
        <ProCarousel responsive={responsive2} arrows={false} >
        {productList.map((x, i) =>
          <div className="ml-2 p-2.5 shadow-md rounded-xl my-5" key={i}>
            <div className='shadow-md w-full rounded-xl'>
              <img
                className="rounded-xl object-cover object-center"
                src={p1}
                alt="fairdeal product"
              />
                <div className=" text-center text-white mt-1 mx-1 rounded-t-lg rounded-b-md bg-orange-800/75 px-2">
                  <small color="white" className="font-bold">
                    Offer: Promocode
                  </small>
                </div>
                <IconButton
                size="sm"
                color="red"
                variant="text"
                className="!absolute top-4 right-4 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-6 w-6"
                >
                  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
                </IconButton>
            </div>
              <div className="mt-2.5 grid grid-cols-2">
              <small
                color="blue-gray"
                className="flex w-full items-center gap-1 ml-1.5"
              >
                5.0
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="-mt-0.5 h-5 w-5 text-yellow-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                    clipRule="evenodd"
                  />
                </svg>
              </small>
              <div className="flex w-full">
                <Tooltip content="Free wifi">
                  <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </Tooltip>
                <Tooltip content={`65" HDTV`}>
                  <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path d="M19.5 6h-15v9h15V6z" />
                      <path
                        fillRule="evenodd"
                        d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </Tooltip>
                <Tooltip content="Fire alert">
                  <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </Tooltip>
                <span className='ml-1 mt-1'>+2</span>
              </div>
              </div>
              <div className="mt-2.5 flex items-center justify-between">
                <small variant="" color="blue-gray" className="font-medium">
                  {x.title}
                </small>
              </div>
              <div className="mt-2.5 font-bold text-gray-800 flex items-center ">
                <BsCurrencyRupee/>{x.price}
                <div>
                  <sub>
                    <del className='text-gray-500'><BsCurrencyRupee style={{float:'left', marginTop:'7px'}}/>2999</del>
                  <span className='text-green-600'> 70% Off</span>
                  </sub>
                </div>
                <button type="button" className="mx-auto rounded-lg text-white p-2 border-2 shadow-md bg-gradient-to-r from-cyan-500 to-blue-500" onClick={() => addProductToBag(x, !x.inCart)}>
                  {/* <span className='mx-auto'><BsHandbagFill/></span> */}
                  {x.inCart ? "Remove from" : "Add to"} Bag
                </button>
              </div>
              <div className='mt-2.5 gap-x-2'>
                  {/* <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-blue-500 to-cyan-500  py-3 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                  >
                    Add to bag
                  </button> */}
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                  >
                    Buy Now
                  </button>
                </div>
          </div>
        )} 
        </ProCarousel>
      {/* products */}
      {/* promobanner */}
          <div className='my-5 pb-5'>
                  <img className='rounded-lg' src={demo_banner} alt="demo_banner"/>
          </div>         
      {/* promobanner */}
      {/* multitab products */}
      <Tabs value="html" className='my-5'>
        <div className='grid grid-cols-2 gap-5 bg-[#cca85a59] rounded-lg p-4'>
          <div><span className='pb-2 text-lg font-bold border-b-2 border-[#cda557]'>NEW PRODUCTS</span>
          </div>
          <div>
          <TabsHeader>
            {data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          </div>
        </div>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
             <ProCarousel responsive={responsive2} arrows={false} >
                  {productList.map((x, i) =>
                    <div className="ml-2 p-2.5 shadow-md rounded-xl" key={i}>
                      <div className='shadow-md w-full rounded-xl'>
                        <img
                          className="rounded-xl object-cover object-center"
                          src={p1}
                          alt="fairdeal product"
                        />
                          <div className=" text-center text-white mt-1 mx-1 rounded-t-lg rounded-b-md bg-orange-800/75 px-2">
                            <small color="white" className="font-bold">
                              Offer: Promocode
                            </small>
                          </div>
                          <IconButton
                          size="sm"
                          color="red"
                          variant="text"
                          className="!absolute top-4 right-4 rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-6 w-6"
                          >
                            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                          </svg>
                          </IconButton>
                      </div>
                        <div className="mt-2.5 grid grid-cols-2">
                        <small
                          color="blue-gray"
                          className="flex w-full items-center gap-1 ml-1.5"
                        >
                          5.0
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="-mt-0.5 h-5 w-5 text-yellow-700"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </small>
                        <div className="flex w-full">
                          <Tooltip content="Free wifi">
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <Tooltip content={`65" HDTV`}>
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path d="M19.5 6h-15v9h15V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <Tooltip content="Fire alert">
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <span className='ml-1 mt-1'>+2</span>
                        </div>
                        </div>
                        <div className="mt-2.5 flex items-center justify-between">
                          <small variant="" color="blue-gray" className="font-medium">
                            {x.title}
                          </small>
                        </div>
                        <div className="mt-2.5 font-bold text-gray-800 flex items-center ">
                          <BsCurrencyRupee/>{x.price}
                          <div>
                            <sub>
                              <del className='text-gray-500'><BsCurrencyRupee style={{float:'left', marginTop:'7px'}}/>2999</del>
                            <span className='text-green-600'> 70% Off</span>
                            </sub>
                          </div>
                          <button type="button" className="mx-auto rounded-lg text-white p-2 border-2 shadow-md bg-gradient-to-r from-cyan-500 to-blue-500" onClick={() => addProductToBag(x, !x.inCart)}>
                            {/* <span className='mx-auto'><BsHandbagFill/></span> */}
                            {x.inCart ? "Remove from" : "Add to"} Bag
                          </button>
                        </div>
                        <div className='mt-2.5 gap-x-2'>
                            {/* <button
                              type="submit"
                              className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-blue-500 to-cyan-500  py-3 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                            >
                              Add to bag
                            </button> */}
                            <button
                              type="submit"
                              className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                            >
                              Buy Now
                            </button>
                          </div>
                    </div>
                  )} 
                  </ProCarousel> 
          </TabPanel>
        ))}
      </TabsBody>
      </Tabs>
      {/* multitab products */}
      {/* shortbanner 2 */}
      <div class="grid grid-cols-2 mx-auto gap-4 my-5">
        <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
        <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
      </div>
      {/* shortbanner  2*/}
      {/* multitab products another */}
      <Tabs value="html" className='my-5 pb-5'>
        <div className='grid grid-cols-2 gap-5 bg-[#cca85a59] rounded-lg p-4'>
          <div>
          <TabsHeader>
            {data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          </div>
          <div>
          <p className='bg-[#cda557] rounded-full mt-2 border float-right text-white'>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </p>
          </div>
        </div>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
             <ProCarousel responsive={responsive2} arrows={false} >
                  {productList.map((x, i) =>
                    <div className="ml-2 p-2.5 shadow-md rounded-xl" key={i}>
                      <div className='shadow-md w-full rounded-xl'>
                        <img
                          className="rounded-xl object-cover object-center"
                          src={p1}
                          alt="fairdeal product"
                        />
                          <div className=" text-center text-white mt-1 mx-1 rounded-t-lg rounded-b-md bg-orange-800/75 px-2">
                            <small color="white" className="font-bold">
                              Offer: Promocode
                            </small>
                          </div>
                          <IconButton
                          size="sm"
                          color="red"
                          variant="text"
                          className="!absolute top-4 right-4 rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-6 w-6"
                          >
                            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                          </svg>
                          </IconButton>
                      </div>
                        <div className="mt-2.5 grid grid-cols-2">
                        <small
                          color="blue-gray"
                          className="flex w-full items-center gap-1 ml-1.5"
                        >
                          5.0
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="-mt-0.5 h-5 w-5 text-yellow-700"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </small>
                        <div className="flex w-full">
                          <Tooltip content="Free wifi">
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <Tooltip content={`65" HDTV`}>
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path d="M19.5 6h-15v9h15V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <Tooltip content="Fire alert">
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <span className='ml-1 mt-1'>+2</span>
                        </div>
                        </div>
                        <div className="mt-2.5 flex items-center justify-between">
                          <small variant="" color="blue-gray" className="font-medium">
                            {x.title}
                          </small>
                        </div>
                        <div className="mt-2.5 font-bold text-gray-800 flex items-center ">
                          <BsCurrencyRupee/>{x.price}
                          <div>
                            <sub>
                              <del className='text-gray-500'><BsCurrencyRupee style={{float:'left', marginTop:'7px'}}/>2999</del>
                            <span className='text-green-600'> 70% Off</span>
                            </sub>
                          </div>
                          <button type="button" className="mx-auto rounded-lg text-white p-2 border-2 shadow-md bg-gradient-to-r from-cyan-500 to-blue-500" onClick={() => addProductToBag(x, !x.inCart)}>
                            {/* <span className='mx-auto'><BsHandbagFill/></span> */}
                            {x.inCart ? "Remove from" : "Add to"} Bag
                          </button>
                        </div>
                        <div className='mt-2.5 gap-x-2'>
                            {/* <button
                              type="submit"
                              className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-blue-500 to-cyan-500  py-3 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                            >
                              Add to bag
                            </button> */}
                            <button
                              type="submit"
                              className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                            >
                              Buy Now
                            </button>
                          </div>
                    </div>
                  )} 
                  </ProCarousel> 
          </TabPanel>
        ))}
      </TabsBody>
       </Tabs>
      {/* multitab products another */}
      {/* short banner 3 */}
      <div class="grid grid-cols-3 mx-auto gap-4 my-5 pb-5">
        <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
        <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
        <div><img className='rounded-lg' src={banner2} alt='short banner'/></div>
      </div>
      {/* short banner 3 */}
      {/* multitab trending products */}
      <Tabs value="html" className='my-5'>
        <div className='grid grid-cols-2 gap-5 bg-[#cca85a59] rounded-lg p-4'>
          <div><span className='pb-2 text-lg font-bold border-b-2 border-[#cda557]'>TRENDING PRODUCTS</span>
          </div>
          <div>
          <TabsHeader>
            {data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          </div>
        </div>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
             <ProCarousel responsive={responsive2} arrows={false} >
                  {productList.map((x, i) =>
                    <div className="ml-2 p-2.5 shadow-md rounded-xl" key={i}>
                      <div className='shadow-md w-full rounded-xl'>
                        <img
                          className="rounded-xl object-cover object-center"
                          src={p1}
                          alt="fairdeal product"
                        />
                          <div className=" text-center text-white mt-1 mx-1 rounded-t-lg rounded-b-md bg-orange-800/75 px-2">
                            <small color="white" className="font-bold">
                              Offer: Promocode
                            </small>
                          </div>
                          <IconButton
                          size="sm"
                          color="red"
                          variant="text"
                          className="!absolute top-4 right-4 rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-6 w-6"
                          >
                            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                          </svg>
                          </IconButton>
                      </div>
                        <div className="mt-2.5 grid grid-cols-2">
                        <small
                          color="blue-gray"
                          className="flex w-full items-center gap-1 ml-1.5"
                        >
                          5.0
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="-mt-0.5 h-5 w-5 text-yellow-700"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </small>
                        <div className="flex w-full">
                          <Tooltip content="Free wifi">
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <Tooltip content={`65" HDTV`}>
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path d="M19.5 6h-15v9h15V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <Tooltip content="Fire alert">
                            <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-1 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                          <span className='ml-1 mt-1'>+2</span>
                        </div>
                        </div>
                        <div className="mt-2.5 flex items-center justify-between">
                          <small variant="" color="blue-gray" className="font-medium">
                            {x.title}
                          </small>
                        </div>
                        <div className="mt-2.5 font-bold text-gray-800 flex items-center ">
                          <BsCurrencyRupee/>{x.price}
                          <div>
                            <sub>
                              <del className='text-gray-500'><BsCurrencyRupee style={{float:'left', marginTop:'7px'}}/>2999</del>
                            <span className='text-green-600'> 70% Off</span>
                            </sub>
                          </div>
                          <button type="button" className="mx-auto rounded-lg text-white p-2 border-2 shadow-md bg-gradient-to-r from-cyan-500 to-blue-500" onClick={() => addProductToBag(x, !x.inCart)}>
                            {/* <span className='mx-auto'><BsHandbagFill/></span> */}
                            {x.inCart ? "Remove from" : "Add to"} Bag
                          </button>
                        </div>
                        <div className='mt-2.5 gap-x-2'>
                            {/* <button
                              type="submit"
                              className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-blue-500 to-cyan-500  py-3 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                            >
                              Add to bag
                            </button> */}
                            <button
                              type="submit"
                              className="flex w-full items-center justify-center rounded-md  bg-gradient-to-r from-cyan-500 to-blue-500  py-3 text-white hover:bg-lime-700 focus:ring-2 focus:ring-blue-gray-900 focus:ring-offset-2"
                            >
                              Buy Now
                            </button>
                          </div>
                    </div>
                  )} 
                  </ProCarousel> 
          </TabPanel>
        ))}
      </TabsBody>
      </Tabs>
      {/* multitab trending products */}
    </div>
    <div class="grid grid-cols-4 mx-auto gap-4 my-5 p-16 bg-gray-200">
        <div>
         <div class="col-lg-3 col-md-6">
                            <div class="shipping-item">
                                <div class="shipping-icon">
                                    <img src="../assets/images/shipping-icon/1.png" alt=" Shipping Icon"/>                                </div>
                                <div class="shipping-content">
                                    <h6>Free Uk Standard Delivery</h6>
                                    <p>Designated day delivery</p>
                                </div>
                            </div>
                        </div> 
        </div>
        <div>
         <div class="col-lg-3 col-md-6">
                            <div class="shipping-item">
                                <div class="shipping-icon">
                                    <img src="../assets/images/shipping-icon/1.png" alt=" Shipping Icon"/>                                </div>
                                <div class="shipping-content">
                                    <h6>Free Uk Standard Delivery</h6>
                                    <p>Designated day delivery</p>
                                </div>
                            </div>
                        </div> 
        </div>
        <div>
         <div class="col-lg-3 col-md-6">
                            <div class="shipping-item">
                                <div class="shipping-icon">
                                    <img src="../assets/images/shipping-icon/1.png" alt=" Shipping Icon"/>                                </div>
                                <div class="shipping-content">
                                    <h6>Free Uk Standard Delivery</h6>
                                    <p>Designated day delivery</p>
                                </div>
                            </div>
                        </div> 
        </div>
        <div>
         <div class="col-lg-3 col-md-6">
                            <div class="shipping-item">
                                <div class="shipping-icon">
                                    <img src="../assets/images/shipping-icon/1.png" alt=" Shipping Icon"/>                                </div>
                                <div class="shipping-content">
                                    <h6>Free Uk Standard Delivery</h6>
                                    <p>Designated day delivery</p>
                                </div>
                            </div>
                        </div> 
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default Home
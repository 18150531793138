import React from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import gLogin from '../img/gLogin.png';
import { Fragment, useEffect, useState } from 'react';
 
export function LoginModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  
  const auth = getAuth();
  const gooleLogin = new GoogleAuthProvider();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredentials) => {
      console.log(userCredentials);
      sessionStorage.setItem('Token', userCredentials.user.accessToken)
      sessionStorage.setItem('uName', userCredentials.user.displayName)
      sessionStorage.setItem('mailid', userCredentials.user.email)
      sessionStorage.setItem('dp', userCredentials.user.photoURL)
      sessionStorage.setItem('uid', userCredentials.user.uid)
      sessionStorage.setItem('phno', userCredentials.user.phoneNumber)
      navigate('/profile')
    })
    .catch((error) => {
      console.log(error);
    })
  };
  const signupWithGoogle = () => {
    signInWithPopup(auth, gooleLogin)
    .then((response) => {
      console.log(response.user)
      sessionStorage.setItem('Token', response.user.accessToken)
      sessionStorage.setItem('uName', response.user.displayName)
      sessionStorage.setItem('mailid', response.user.email)
      sessionStorage.setItem('dp', response.user.photoURL)
      sessionStorage.setItem('uid', response.user.uid)
      navigate('/profile')
    })
  }
  
  return (
    <>
      <Button className="rounded-md bg-[#cca85a] hover:text-white hover:bg-gradient-to-r from-cyan-500 to-blue-500" onClick={handleOpen}>Sign In</Button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-[20em] max-w-[24rem]">
                            <CardHeader
                              variant="gradient"
                              color="blue"
                              className="mb-4 grid h-28 place-items-center"
                            >
                              <Typography variant="h3" color="white">
                                Sign In
                              </Typography>
                            </CardHeader>
                            <CardBody className="flex flex-col gap-4">
                              <Input label="Email" size="lg" onChange={(e) => setEmail(e.target.value)} value={email} />
                              <Input label="Password" size="lg" onChange={(e) => setPassword(e.target.value)} value={password}/>
                              <div className="-ml-2.5">
                                <Checkbox label="Remember Me" />
                              </div>
                            </CardBody>
                            <CardFooter className="pt-0">
                              <Button variant="gradient" onClick={handleLogin} fullWidth>
                                Sign In
                              </Button>
                              <img className='mt-2 shadow-md rounded cursor-pointer'
                              src={gLogin}
                              alt="google_login"
                              onClick={signupWithGoogle} 
                              />
                              <Typography variant="small" className="mt-2 flex justify-center">
                                Don&apos;t have an account?
                                <Typography
                                  as="a"
                                  href="signup"
                                  variant="small"
                                  color="blue"
                                  className="ml-1 font-bold cursor-pointer"
                                >
                                  Sign up
                                </Typography>
                              </Typography>
                            </CardFooter>
                          </Card>
      </Dialog>
    </>
  );
}